import { ITreeSubmission, ITreeSubmissionTreeEntry } from "../interfaces/interfaces"

export const sanitizeFetchedSubmissions = (submissions: (Omit<ITreeSubmission, "dateCreated" | "info"> & { dateCreated: number, info: Array<(Omit<ITreeSubmissionTreeEntry, "birthdate"> & { birthdate: number })> })[]) => {
    const sanitizedSubmissions: ITreeSubmission[] = [];
    submissions.forEach(s => {
        sanitizedSubmissions.push({
            ...s,
            dateCreated: new Date(s.dateCreated),
            info: s.info.map(info => ({
                amt: info.amt,
                species: info.species,
                birthdate: new Date(info.birthdate)
            }))
        })
    });
    return sanitizedSubmissions;
}