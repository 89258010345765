import { useState, useEffect } from "react";
import styled from "styled-components";
import {
	RegularTextBlock,
	RegularTitleTextBlock,
	SmallTextBlock,
} from "../components.tsx/text";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ITreeSubmission } from "../interfaces/interfaces";
import { countryCodeToName } from "../util/countries";
import { RectangularButton } from "../components.tsx/buttons";
import { Lightbox } from "../components.tsx/lightbox";
import { acceptNewTreeSubmission, rejectNewTreeSubmission } from "../util/api";
import { BouncingDots } from "../components.tsx/loader";
import { calculateSubmissionTreeCount } from "../util/treeCounts";

const Page = styled.div`
	padding: 20px 40px;
`;

const PaddedSmallText = styled(SmallTextBlock)`
	padding: 2.5px 0px;
`;

const PaddedRegularTitleTextBlock = styled(RegularTitleTextBlock)`
	padding: 25px 0px;
`;

const SubmissionSection = styled.div`
	padding: 20px 0px;
`;

const EntriesList = styled.div`
	padding: 0px 40px;
	display: flex;
	flex-direction: column;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

const EntriesListRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	border-top: 1px solid black;
`;

const EntriesListRowEntry = styled(SmallTextBlock)`
	padding: 20px 20px;
	flex: 1;
`;

const SquareImg = styled.div<{ imgSrc: string; width: number; height: number }>`
	background-image: ${(props) => `url(${props.imgSrc})`};
	width: ${(props) => props.width}px;
	height: ${(props) => props.height}px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	cursor: pointer;
`;

function SubmissionOverviewPage() {
	const location = useLocation<ITreeSubmission>();
	const history = useHistory();

	const [lightboxImgUrls, setLightboxImgUrls] = useState<
		string[] | undefined
	>();
	const [lightboxIndex, setLightboxIndex] = useState(0);

	const submission = location.state;
	const entries = submission.info.sort((a, b) => a.amt - b.amt);
	const user = submission.creatorInfo;
	const orgInfo = submission.organizationInfo;
	const [loading, setLoading] = useState(false);

	if (loading) {
		return (
			<div style={{ width: "100", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
				<BouncingDots />
			</div>
		);
	}
	return (
		<Lightbox
			backgroundColor="rgba(0, 0, 0, 0.9)"
			imgUrls={lightboxImgUrls}
			index={lightboxIndex}
			onNext={() =>
				setLightboxIndex((lightboxIndex + 1) % lightboxImgUrls!.length)
			}
			onPrevious={() =>
				setLightboxIndex((lightboxIndex - 1) % lightboxImgUrls!.length)
			}
			onClose={() => setLightboxImgUrls(undefined)}
		>
			<Page>
				<RegularTitleTextBlock style={{ paddingBottom: 0, color: "red" }}>
					{countryCodeToName(submission.country)}
				</RegularTitleTextBlock>
				<RegularTitleTextBlock style={{ paddingBottom: 0, color: "red" }}>
					{calculateSubmissionTreeCount(submission)} trees
				</RegularTitleTextBlock>
				<RegularTitleTextBlock style={{ paddingBottom: 0 }}>
					{user.firstName} {user.lastName} {orgInfo ? `- ${orgInfo.name} (org)` : ""}
				</RegularTitleTextBlock>
				<PaddedSmallText>{user!.email}</PaddedSmallText>
				<SubmissionSection>
					<EntriesList style={{ paddingTop: 20 }}>
						{!entries.length ? <div>No entries</div> : <></>}
						<EntriesListRow
							style={{
								borderBottom: "1px solid black",
								borderTopWidth: 0,
							}}
						>
							<EntriesListRowEntry>Amount</EntriesListRowEntry>
							<EntriesListRowEntry>Species</EntriesListRowEntry>
							<EntriesListRowEntry>Birthdate</EntriesListRowEntry>
						</EntriesListRow>
						{entries.map((entry, index) => (
							<EntriesListRow
								key={index}
							>
								<EntriesListRowEntry>
									{entry.amt}
								</EntriesListRowEntry>
								<EntriesListRowEntry>
									{entry.species[0].toUpperCase() +
										entry.species
											.substring(1)
											.toLowerCase()}
								</EntriesListRowEntry>
								<EntriesListRowEntry>
									{entry.birthdate.toDateString()}
								</EntriesListRowEntry>
							</EntriesListRow>
						))}
					</EntriesList>
				</SubmissionSection>
				{submission.story ? (
					<div>
						<RegularTitleTextBlock>Story:</RegularTitleTextBlock>
						<RegularTextBlock>{submission.story!}</RegularTextBlock>
					</div>
				) : (
					<></>
				)}
				<Row
					style={{
						paddingBottom: 150,
						paddingTop: submission.story ? 20 : 0,
					}}
				>
					<div style={{ flex: 1 }}>
						<PaddedRegularTitleTextBlock>
							Pictures Before
						</PaddedRegularTitleTextBlock>
						<Row style={{ flexWrap: "wrap" }}>
							{submission.beforeImgUrls.map((src, index) => (
								<SquareImg
									key={`beforeImg${index}`}
									imgSrc={src}
									width={150}
									height={150}
									onClick={() => {
										setLightboxImgUrls(
											submission.beforeImgUrls
										);
									}}
								/>
							))}
						</Row>
					</div>
					<div style={{ width: 50 }} />
					<div style={{ flex: 1 }}>
						<PaddedRegularTitleTextBlock>
							Pictures After
						</PaddedRegularTitleTextBlock>
						<Row style={{ flexWrap: "wrap" }}>
							{submission.afterImgUrls.map((src, index) => (
								<SquareImg
									key={`afterImg${index}`}
									imgSrc={src}
									width={150}
									height={150}
									onClick={() => {
										setLightboxImgUrls(
											submission.afterImgUrls
										);
									}}
								/>
							))}
						</Row>
					</div>
				</Row>
				{submission.status === "pending" ?
					<Row style={{ justifyContent: "center" }}>
						{/* <RectangularButton backgroundColor="orange">
				Doubt
			</RectangularButton> */}
						<RectangularButton
							backgroundColor="red"
							style={{ margin: "0px 20px" }}
							onClick={() => {
								setLoading(true);
								rejectNewTreeSubmission(submission.id).then((err) => {
									if (err) {
										alert(err);
									}
									history.replace("/");
								});
							}}
						>
							Not OK
						</RectangularButton>
						<RectangularButton
							backgroundColor="green"
							onClick={() => {
								setLoading(true);
								acceptNewTreeSubmission(submission.id).then((err) => {
									if (err) {
										alert(err);
									}
									history.replace("/");
								});
							}}
						>
							Verify
						</RectangularButton>
					</Row> : <></>
				}
				<div style={{ paddingTop: 30, display: "flex", justifyContent: "center" }}>
					<RectangularButton style={{ width: "50%" }} onClick={() => history.replace("/")}>
						Main Page
					</RectangularButton>
				</div>
			</Page>
		</Lightbox>
	);
}

export default SubmissionOverviewPage;
