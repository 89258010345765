import { ITreeSubmission } from "../interfaces/interfaces";
import { sanitizeFetchedSubmissions } from "./sanatization";

export const apiUrl = process.env.NODE_ENV === "production" ? "https://admin.earthproject.org" : "http://localhost:8000/api";

const handleRedirect = (url: string) => {
    window.location.href = url;
}

/**
 * 
 * @param email 
 * @param password 
 * @returns A string if an error occured.
 */
export const login = async (email: string, password: string): Promise<string | undefined> => {
    const response = await fetch(`/api/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email, password
        })
    });
    const body = await response.json();
    if (body.status !== "success") {
        return "Could not log in";
    }
    return;
};

/**
 * 
 * @param pageId 
 * @returns A promise which includes in order: tree submissions, current page id, next page id, error
 */
export const loadPendingNewTreeSubmissions = async (pageInfo?: [startPageId: string, page: number]): Promise<[ITreeSubmission[] | undefined, string | undefined, string | undefined]> => {
    try {
        const startPageId = pageInfo?.[0];
        const pageNumber = pageInfo?.[1];
        const query = startPageId ? `?startPageId=${startPageId}&page=${pageNumber}` : "";
        try {
            const res = await fetch(`/api/pendingNewSubmissions${query}`, { redirect: "follow" });
            if (res.redirected) {
                handleRedirect(res.url);
            }
            const body = await res.json();
            if (body.status === "error") {
                return [undefined, undefined, body.message];
            } else {
                const submissions = sanitizeFetchedSubmissions(body.data)
                return [submissions, startPageId ?? submissions[0]?.id ?? undefined, undefined];
            }
        } catch (e) {
            return [undefined, undefined, "Could not fetch submissions"];
        }
    } catch (e) {
        return [undefined, undefined, "Could not load user data"];
    }
};

/**
 * 
 * @param pageId 
 * @returns A promise which includes in order: tree submissions, current page id, next page id, error
 */
export const loadRejectedTreeSubmissions = async (pageInfo?: [startPageId: string, page: number]): Promise<[ITreeSubmission[] | undefined, string | undefined, string | undefined]> => {
    try {
        const startPageId = pageInfo?.[0];
        const pageNumber = pageInfo?.[1];
        const query = startPageId ? `?startPageId=${startPageId}&page=${pageNumber}` : "";
        try {
            const res = await fetch(`/api/rejectedSubmissions${query}`, { redirect: "follow" });
            if (res.redirected) {
                handleRedirect(res.url);
            }
            const body = await res.json();
            if (body.status === "error") {
                return [undefined, undefined, body.message];
            } else {
                const submissions = sanitizeFetchedSubmissions(body.data);
                return [submissions, startPageId ?? submissions[0]?.id ?? undefined, undefined];
            }
        } catch (e) {
            console.log(e);
            return [undefined, undefined, "Could not fetch submissions"];
        }
    } catch (e) {
        return [undefined, undefined, "Could not load user data"];
    }
};

/**
 * 
 * @param pageId 
 * @returns A promise which includes in order: tree submissions, current page id, next page id, error
 */
export const loadAcceptedTreeSubmissions = async (pageInfo?: [startPageId: string, page: number]): Promise<[ITreeSubmission[] | undefined, string | undefined, string | undefined]> => {
    try {
        const startPageId = pageInfo?.[0];
        const pageNumber = pageInfo?.[1];
        const query = startPageId ? `?startPageId=${startPageId}&page=${pageNumber}` : "";
        try {
            const res = await fetch(`/api/acceptedSubmissions${query}`, { redirect: "follow" });
            if (res.redirected) {
                handleRedirect(res.url);
            }
            const body = await res.json();
            if (body.status === "error") {
                return [undefined, undefined, body.message];
            } else {
                const submissions = sanitizeFetchedSubmissions(body.data);
                return [submissions, startPageId ?? submissions[0]?.id ?? undefined, undefined];
            }
        } catch (e) {
            console.log(e);
            return [undefined, undefined, "Could not fetch submissions"];
        }
    } catch (e) {
        return [undefined, undefined, "Could not load user data"];
    }
};

/**
 * 
 * @returns A string if an error occured.
 */
export const acceptNewTreeSubmission = async (submissionId: string): Promise<string | undefined | void> => {
    const response = await fetch(`/api/acceptNewSubmissions`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            submissionId
        })
    });
    if (response.redirected) {
        return handleRedirect(response.url);
    }
    const body = await response.json();
    if (body.status !== "success") {
        return "Could not accept submission";
    }
    return;
};

/**
 * 
 * @returns A string if an error occured.
 */
export const rejectNewTreeSubmission = async (submissionId: string): Promise<string | undefined | void> => {
    const response = await fetch(`/api/rejectNewSubmissions`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            submissionId
        })
    });
    if (response.redirected) {
        return handleRedirect(response.url);
    }
    const body = await response.json();
    if (body.status !== "success") {
        return "Could not reject submission";
    }
    return;
};

/**
 * 
 * @returns A promise which includes in order: total info, error
 */
export const loadTotals = async (pageInfo?: [startPageId: string, page: number]): Promise<[{ trees: number, carbon: number } | undefined, string | undefined]> => {
    try {
        try {
            const res = await fetch(`/api/totals`, { redirect: "follow" });
            if (res.redirected) {
                handleRedirect(res.url);
            }
            const body = await res.json();
            if (body.status === "error") {
                return [undefined, body.message];
            } else {
                return [body.data, undefined];
            }
        } catch (e) {
            return [undefined, "Could not fetch totals"];
        }
    } catch (e) {
        return [undefined, "Could not fetch totals"];
    }
};