import styled from "styled-components";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
`;

export function Lightbox(
	props: React.PropsWithChildren<{
		backgroundColor: string;
		imgUrls?: string[];
		index: number;
		onNext: () => void;
		onPrevious: () => void;
		onClose: () => void;
	}>
) {
	const visible = props.imgUrls !== undefined;
	return (
		<div
			style={{
				position: "relative",
				width: window.innerWidth,
				height: window.innerHeight,
				overflowY: visible ? "hidden" : "visible",
			}}
		>
			{props.children}
			{visible ? (
				<div
					style={{
						position: "absolute",
						zIndex: 10000,
						top: 0,
						width: window.innerWidth,
						height: window.innerHeight,
						backgroundColor: props.backgroundColor,
						fontSize: 35,
						fontWeight: "bold",
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
							alignItems: "flex-end",
							color: "white",
							height: "100%",
						}}
					>
						<div
							style={{
								padding: 30,
								cursor: "pointer",
							}}
							onClick={props.onClose}
						>
							X
						</div>
						<Row
							style={{
								height: "100%",
								justifyContent: "center",
								alignSelf: "center",
								justifySelf: "center",
								textAlign: "center",
								verticalAlign: "center",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									paddingRight: 50,
									cursor: "pointer",
								}}
								onClick={props.onPrevious}
							>
								<div>{"<"}</div>
							</div>
							<div
								style={{
									backgroundImage: `url(${
										props.imgUrls![props.index]
									})`,
									backgroundPosition: "center",
									backgroundSize: "contain",
									backgroundRepeat: "no-repeat",
									width: window.innerWidth * 0.75,
									height: window.innerHeight * 0.75,
								}}
							/>
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									paddingLeft: 50,
									cursor: "pointer",
								}}
								onClick={props.onNext}
							>
								<div>{">"}</div>
							</div>
						</Row>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
}
