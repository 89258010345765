import { useState, useEffect } from "react";
import styled from "styled-components";
import { RectangularButton } from "../components.tsx/buttons";
import RectangularDropdown from "../components.tsx/RectangularDropdown";
import {
	RegularTitleTextBlock,
	SmallTextBlock,
	SmallTitleTextBlock,
} from "../components.tsx/text";
import { RectangularTextField } from "../components.tsx/textfields";
import { IUser } from "../types/users";
import { login } from "../util/api";

const Page = styled.div`
	padding: 20px 40px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
`;

const PaddedRegularTitle = styled(RegularTitleTextBlock)`
	padding: 20px 20px;
`;
const PaddedSmallTitle = styled(SmallTitleTextBlock)`
	padding: 2.5px 0px;
`;
const PaddedSmallText = styled(SmallTextBlock)`
	padding: 2.5px 0px;
`;

const SubmissionSection = styled.div`
	padding: 20px 0px;
`;

function LoginPage() {
	const [error, setError] = useState<string | undefined>();
	const [email, setEmail] = useState<string>("");
	const [password, setPassword] = useState<string>("");

	const urlSearchParams = new URLSearchParams(window.location.search);
	const { redirect } = Object.fromEntries(urlSearchParams.entries());

	const onLogin = () => {
		login(email, password).then((err) => {
			if (err) {
				setError(err);
				return;
			}
			if (redirect) {
				window.location.href = redirect;
			} else {
				window.location.href = "/";
			}
		});
	};

	return (
		<Page>
			<PaddedRegularTitle>Login</PaddedRegularTitle>
			<div style={{ paddingBottom: 20 }}>
				<PaddedSmallTitle>Email</PaddedSmallTitle>
				<RectangularTextField
					onChange={(s) => setEmail(s.target.value)}
					placeholder="Email"
					border
				/>
			</div>
			<div style={{ paddingBottom: 10 }}>
				<PaddedSmallTitle>Password</PaddedSmallTitle>
				<RectangularTextField
					onChange={(s) => setPassword(s.target.value)}
					placeholder="Password"
					border
					isSecure
				/>
			</div>
			<div style={{ paddingBottom: 10, color: "red" }}>{error}</div>
			<RectangularButton onClick={onLogin}>Submit</RectangularButton>
		</Page>
	);
}

export default LoginPage;
