import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import TreePlantingSubmissionsList from "./pages/TreePlantingSubmissionsList";
import reportWebVitals from "./reportWebVitals";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import LoginPage from "./pages/Login";
import SubmissionOverviewPage from "./pages/SubmissionOverview";

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<Switch>
				<Route exact path="/" component={TreePlantingSubmissionsList} />
				<Route exact path="/login" component={LoginPage} />
				<Route
					exact
					path="/submissionOverview"
					component={SubmissionOverviewPage}
				/>
			</Switch>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
