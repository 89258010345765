import { useState, useEffect } from "react";
import styled from "styled-components";
import RectangularDropdown from "../components.tsx/RectangularDropdown";
import {
	RegularTitleTextBlock,
	SmallTextBlock,
	SmallTitleTextBlock,
} from "../components.tsx/text";
import { RectangularButton } from "../components.tsx/buttons";
import { loadAcceptedTreeSubmissions, loadPendingNewTreeSubmissions, loadRejectedTreeSubmissions, loadTotals } from "../util/api";
import { ITreeSubmission, TreeSubmissionStatus } from "../interfaces/interfaces";
import { useHistory } from "react-router-dom";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Spinner, VerticalLoader } from "../components.tsx/loaders";
import { BouncingDots } from "../components.tsx/loader";
import { calculateSubmissionTreeCount } from "../util/treeCounts";
import { blue } from "../globals/colors";
import { countryCodeToName } from "../util/countries";

const Page = styled.div``;
const TopBar = styled.div`
	padding: 20px 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;
const PaddedRegularTitle = styled(RegularTitleTextBlock)`
	padding: 20px 20px;
`;
const PaddedDropdown = styled(RectangularDropdown)`
	padding: 20px 20px;
	width: 500px;
`;

const PaddedSmallTitle = styled(SmallTitleTextBlock)`
	padding: 20px 20px;
`;

const UsersListRow = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	cursor: pointer;
	align-items: center;
`;
const UserListRowEntry = styled(SmallTextBlock)`
	padding: 20px 20px;
	flex: 1;
`;

const UsersListHeader = styled.div`
	padding: 0px 40px;
	display: flex;
	flex-direction: row;
`;

const TreeSubmissionsList = styled.div`
	padding: 0px 40px;
	display: flex;
	flex-direction: column;
`;
const PaginationContainer = styled.div`
	padding: 40px 40px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
`;
const Clickable = styled.div`
	cursor: pointer;
`;

const ListSwitchButton = styled.div<{ active?: boolean }>`
	background-color: ${props => props.active ? blue : "transparent"};
	color: ${props => props.active ? "white" : "black"};
	border: 1px solid ${blue};
	padding: 15px 30px;
	cursor: pointer;
`;

function TreePlantingSubmissionsList() {
	const history = useHistory();

	const [selectedCountry, setSelectedCountry] = useState<
		{ code: string; name: string } | undefined
	>();
	const [submissions, setSubmissions] = useState<ITreeSubmission[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const [pageId, setPageId] = useState<string | undefined>();
	const [page, setPage] = useState(0);
	const [error, setError] = useState<string | undefined>();
	const [totals, setTotals] = useState<{ trees: number, carbon: number }>();
	const [activeList, _setActiveList] = useState<TreeSubmissionStatus>("pending");

	const previewImgDim = 150;

	useEffect(() => {
		setSubmissions([]);
		setPage(0);
		setPageId(undefined);
		setLoading(true);
		let submissionsPromise = loadPendingNewTreeSubmissions();
		if (activeList === "accepted") {
			submissionsPromise = loadAcceptedTreeSubmissions();
		} else if (activeList === "rejected") {
			submissionsPromise = loadRejectedTreeSubmissions();
		}
		Promise.all(
			[
				!totals ? loadTotals() : new Promise<undefined>((res) => res(undefined)),
				submissionsPromise
			]
		).then(([totalsResult, listResult]) => {
			let error = "";
			// Totals result //
			if (totalsResult) {
				const [totalsData, totalsError] = totalsResult;
				if (!totalsError) {
					setTotals(totalsData);
					setLoading(false);
					setError(undefined);
				} else {
					error = totalsError;
				}
			}
			// List result //
			console.log(listResult);
			const [listData, newPageId, listError] = listResult;
			if (!listError) {
				if (!pageId) {
					setPageId(newPageId);
				}
				if (listData!.length) {
					setSubmissions(listData!);
				}
				setLoading(false);
				setError(undefined);
			} else {
				if (error?.length) error += " & " + listError;
				else error = listError;
			}

			// Unified
			if (error) {
				setError(error);
			}

		});
	}, [selectedCountry, activeList]);

	const onNextPage = () => {
		if (!submissions.length) return;
		setLoading(true);
		loadPendingNewTreeSubmissions([pageId!, page + 1]).then(([result, _, err]) => {
			if (err) {
				console.log(err);
				setError(err);
			} else {
				if (result!.length) {
					setSubmissions(result!);
					setPage((page ?? 0) + 1);
				}
				setError(undefined);
			}
			setLoading(false);
		});
	}

	const onPreviousPage = () => {
		if (page - 1 < 0) return;
		setLoading(true);
		loadPendingNewTreeSubmissions([pageId!, page - 1]).then(([result, _, err]) => {
			if (err) {
				console.log(err);
				setError(err);
			} else {
				if (result!.length) {
					setSubmissions(result!);
					setPage(page! - 1);
				}
				setError(undefined);
			}
			setLoading(false);
		});
	}

	const getSubmissionTreeSpecies = (submission: ITreeSubmission) => {
		let species = submission.info[0].species;
		for (let i = 1; i < submission.info.length; i++) {
			if (species !== submission.info[i].species) {
				species = "multiple";
				break;
			}
		}
		return species[0].toUpperCase() + species.substring(1);
	};

	const createSubmissionsList = () => {
		if (loading) return <div><VerticalLoader /></div>;
		if (error) return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", paddingTop: 20 }}><div>{error}</div></div>;
		if (!submissions.length) return <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", paddingTop: 20 }}><div>No submissions found</div></div>;

		const goToSubmission = (s: ITreeSubmission) => {
			history.push({
				pathname: "/submissionOverview",
				state: s,
			});
		}

		return (
			<>
				<TreeSubmissionsList>
					{submissions.map((s) => {
						return (
							<UsersListRow
								key={s.id}
								onClick={() => goToSubmission(s)}
							>
								<div
									style={{
										width: previewImgDim,
										height: previewImgDim,
										backgroundImage: `url(${s.afterImgUrls[0]})`,
										backgroundPosition: "center",
										backgroundSize: "contain",
										backgroundRepeat: "no-repeat",
									}}
								></div>
								<UserListRowEntry>
									{s.creatorInfo.firstName}{" "}
									{s.creatorInfo.lastName}
								</UserListRowEntry>
								<UserListRowEntry>
									{getSubmissionTreeSpecies(s)}
								</UserListRowEntry>
								<UserListRowEntry>{countryCodeToName(s.country)}</UserListRowEntry>
								<UserListRowEntry>
									{calculateSubmissionTreeCount(s)}
								</UserListRowEntry>
								<RectangularButton
									onClick={() => goToSubmission(s)}
									style={{ flex: 1 }}
								>
									Verify
								</RectangularButton>
							</UsersListRow>
						);
					})}
				</TreeSubmissionsList>
				<PaginationContainer>
					{loading ? <Spinner /> : <>
						<Clickable
							onClick={onPreviousPage}
						>
							<FaChevronLeft />
						</Clickable>
						<PaddedSmallTitle>
							Page {(page ?? 0) + 1}
						</PaddedSmallTitle>
						<Clickable
							onClick={onNextPage}
						>
							<FaChevronRight />
						</Clickable>
					</>}

				</PaginationContainer>
			</>
		);
	};

	const changeActiveList = (list: TreeSubmissionStatus) => {
		if (activeList === list) return;
		_setActiveList(list);
	}

	return (
		<Page>
			<TopBar>
				<PaddedRegularTitle style={{ textDecoration: "underline" }}>
					Tree Planting Submissions
				</PaddedRegularTitle>
				<PaddedSmallTitle>
					Carbon saved: {totals ? (totals.carbon / 1000) + "t" : ""}
				</PaddedSmallTitle>
			</TopBar>
			<div style={{ display: "flex", justifyContent: "center", paddingBottom: 20 }}>
				<ListSwitchButton active={activeList === "pending"} onClick={() => changeActiveList("pending")}>Pending</ListSwitchButton>
				<ListSwitchButton active={activeList === "accepted"} onClick={() => changeActiveList("accepted")}>Accepted</ListSwitchButton>
				<ListSwitchButton active={activeList === "rejected"} onClick={() => changeActiveList("rejected")}>Rejected</ListSwitchButton>
			</div>
			{loading ? <BouncingDots /> :
				<>
					<UsersListHeader>
						<div style={{ width: previewImgDim }} />
						<PaddedSmallTitle style={{ flex: 1 }}>
							Tree planter
						</PaddedSmallTitle>
						<PaddedSmallTitle style={{ flex: 1 }}>Species</PaddedSmallTitle>
						<PaddedSmallTitle style={{ flex: 1 }}>Country</PaddedSmallTitle>
						<PaddedSmallTitle style={{ flex: 1 }}>
							Planted trees
						</PaddedSmallTitle>
						<div style={{ flex: 1 }} />
					</UsersListHeader>
					{createSubmissionsList()}
				</>
			}
		</Page>
	);
}

export default TreePlantingSubmissionsList;
